<template>
  <div id="settings-wrapper" />
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable';
  import { mapMutations, mapState } from 'vuex';

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      color: '#F16437',
      colors: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#F16437',
        '#E91E63',
        '#FF5252',
      ],
      image: require('@/assets/sidebar-3.jpg'),
      images: [
        require('@/assets/sidebar-3.jpg'),
        require('@/assets/sidebar-1.jpg'),
        require('@/assets/sidebar-2.jpg'),
        require('@/assets/sidebar-4.jpg'),
      ],
      menu: false,
      saveImage: '',
      showImg: true,
    }),

    computed: {
      ...mapState(['barImage']),
    },

    watch: {
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val;
      },
      showImg (val) {
        if (!val) {
          this.saveImage = this.barImage;
          this.setBarImage('');
        } else if (this.saveImage) {
          this.setBarImage(this.saveImage);
          this.saveImage = '';
        } else {
          this.setBarImage(val);
        }
      },
      image (val) {
        this.setBarImage(val);
      },
    },

    methods: {
      ...mapMutations({
        setBarImage: 'SET_BAR_IMAGE',
      }),
    },
  };
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
